:root {
	--main-color: #8ef9c3;
	--main-black: #000;
	--main-white: #fff;
	--gray-color: #696969;
	--second-black: #16181d;
	--third-black: #1e2025;
}

body {
	margin: 0;
	font-family: 'Rubik', sans-serif !important;
	-webkit-font-smoothing: 'Rubik', sans-serif !important;
	-moz-osx-font-smoothing: 'Rubik', sans-serif !important;
}
