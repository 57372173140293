.home_container {
	background-color: var(--main-color);
	height: 100vh;
	position: relative;
}

.seventy_vh {
	height: 70vh;
}

.home_main_content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 60vh;
}

.home_main_content_fill_one {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.title {
	font-family: Rubik;
	font-style: normal;
	font-weight: bold;
	font-size: 70px;
	line-height: 116.1%;
	letter-spacing: -0.5px;
	color: var(--main-black);
	text-align: center;
	width: 75%;
	margin-bottom: 30px;
}

.subtitle {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 25px;
	line-height: 116.1%;
	color: var(--main-black);
	letter-spacing: -0.5px;
	text-align: center;
	width: 70%;
}

.form_container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.form_content_container {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--main-white);
	box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
	border-radius: 7px;
	padding: 3px;
	width: 50%;
	border: 2px solid transparent;
}

.invalid_form_content_container {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--main-white);
	box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
	border-radius: 7px;
	padding: 3px;
	width: 50%;
	border: 2px solid #f00;
}

.email_container {
	margin-right: 5px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	width: 75%;
}

.email_input {
	background-color: var(--main-white);
	padding-left: 15px;
	border-width: 0;
	outline-width: 0;
	font-size: 20px;
	width: 90%;
	color: #000 !important;
}

.emailIcon {
	font-size: 28px;
}

.submit_button_container {
	width: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.submit_button {
	width: 100%;
	border-radius: 7px;
	background-color: var(--main-black);
	color: var(--main-white);
	border-width: 0;
	padding: 10px;
	padding-right: 15px;
	padding-left: 15px;
}

.first_image {
	position: absolute;
	left: -30px;
	top: 15%;
	z-index: 0;
}

.second_image {
	position: absolute;
	right: 0px;
	top: 50%;
	z-index: 0;
}

.downloadButtonCol {
	display: flex;
	align-items: center;
	justify-content: center;
}

.downloadImgAnchor {
	margin-right: 15px;
	margin-top: 20px;
}

.downloadImg {
	margin-right: 15px;
	margin-top: 20px;
}

.mockContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	height: calc(100vh - 70px);
}

.mockImage {
	transform: scale(0.7);
}

.downloadContentContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: calc(100vh - 70px);
}

.textAlignStart {
	text-align: start;
}

@media only screen and (max-width: 767px) {
	.title {
		font-size: 25px;
		margin-bottom: 20px;
		text-align: center !important;
	}

	.subtitle {
		font-size: 15px;
		text-align: center !important;
	}

	.form_content_container {
		width: 100%;
	}

	.invalid_form_content_container {
		width: 100%;
	}

	.email_container {
		padding-left: 5px;
	}

	.email_input {
		font-size: 14px;
		padding-left: 5px;
	}

	.emailIcon {
		font-size: 18px;
	}

	.submit_button {
		padding: 5px;
		padding-right: 7px;
		padding-left: 7px;
		font-size: 11px;
	}

	.first_image {
		width: 80px;
		height: auto;
	}

	.second_image {
		right: 0px;
		width: 80px;
		height: auto;
	}

	.downloadContentContainer {
		align-items: center;
		justify-content: center;
	}

	.mockContainer {
		display: none;
	}

	.downloadButtons {
		align-items: center;
		justify-content: center;
	}

	.downloadImgAnchor {
		margin-right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.downloadImg {
		transform: scale(0.7);
		margin-top: 0;
		margin-right: 0;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.title {
		font-size: 35px;
		margin-bottom: 20px;
	}

	.subtitle {
		font-size: 20px;
	}

	.form_content_container {
		width: 70%;
	}

	.invalid_form_content_container {
		width: 100%;
	}

	.submit_button {
		padding: 5px;
		padding-right: 10px;
		padding-left: 10px;
		font-size: 16px;
	}

	.first_image {
		width: 120px;
		height: auto;
	}

	.second_image {
		right: 0px;
		width: 120px;
		height: auto;
	}

	.mockImage {
		transform: scale(0.5);
	}

	.downloadButtons {
		align-items: center;
		justify-content: center;
	}

	.downloadImgAnchor {
		margin-right: 10px;
	}

	.downloadImg {
		transform: scale(0.85);
		margin-top: 0;
	}
}
