.home_html {
	background: var(--second-black);
}

.aboutSectionMainContainer {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.aboutSectionContainer {
	position: relative;
	background: var(--third-black);
	padding: 30px;
}

.moduleBorderWrap {
	border-radius: 5px;
	position: relative;
	background: linear-gradient(120deg, var(--main-color) 50%, #1e202550);
	padding: 10px;
}

.aboutSectionTitle {
	font-family: Rubik;
	font-style: normal;
	font-weight: bold;
	font-size: 45px;
	letter-spacing: -0.5px;
	color: #ffffff;
}

.aboutSectionParagraph {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	letter-spacing: -0.5px;
	color: #ffffff;
}

.joinusSubtitle {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 116.1%;
	letter-spacing: -0.5px;
	color: #8ef9c3;
}

.contactButton {
	background: var(--main-color) !important;
	border-radius: 3px !important;
	border-color: var(--main-color) !important;
	font-family: Rubik !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 21px !important;
	color: var(--main-black) !important;
	text-transform: uppercase !important;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	padding-right: 15px !important;
	padding-left: 15px !important;
}

@media only screen and (max-width: 767px) {
	.aboutSectionTitle {
		font-size: 18px;
	}

	.aboutSectionParagraph {
		font-size: 14px;
	}

	.joinusSubtitle {
		font-size: 14px;
	}

	.contactButton {
		font-size: 10px !important;
		padding-right: 5px !important;
		padding-left: 5px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.aboutSectionTitle {
		font-size: 30px;
	}

	.aboutSectionParagraph {
		font-size: 16px;
	}

	.joinusSubtitle {
		font-size: 16px;
	}

	.contactButton {
		font-size: 13px !important;
		padding: 7px !important;
	}
}
