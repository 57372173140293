.contactMainContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 50px;
	padding-bottom: 50px;
}

.contactContainer {
	background: var(--third-black);
	border-radius: 5px 5px 0px 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 35px;
	position: relative;
}

.contactTextContainer,
.contactButtonContainer {
	z-index: 5;
}

.contactTitle {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	line-height: 116.1%;
	letter-spacing: -0.5px;
	color: #ffffff;
}

.contactButton {
	background: var(--main-color) !important;
	border-radius: 3px !important;
	border-color: var(--main-color) !important;
	font-family: Rubik !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 21px !important;
	color: var(--main-black) !important;
	text-transform: uppercase !important;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	padding-right: 15px !important;
	padding-left: 15px !important;
}

.contactButton:focus {
	box-shadow: none !important;
	outline: 4px solid #8ef9c350 !important;
}

.contact_images .image_1 {
	position: absolute;
	right: 15%;
	top: 0;
}

.contact_images .image_2 {
	position: absolute;
	right: 25%;
	top: 25%;
	width: 20px;
	height: 20px;
}

@media only screen and (max-width: 767px) {
	.contactMainContainer {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.contactContainer {
		padding: 10px;
		padding-top: 15px;
		padding-bottom: 15px;
		width: 90%;
	}

	.contactTitle {
		font-size: 16px;
		margin: 0 !important;
	}

	.contactButton {
		font-size: 10px !important;
		padding-right: 5px !important;
		padding-left: 5px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}

	.contact_images .image_1 {
		top: 0;
		width: 20px;
		height: auto;
	}

	.contact_images .image_2 {
		top: 5%;
		width: 10px;
		height: auto;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.contactMainContainer {
		padding-top: 35px;
		padding-bottom: 35px;
	}

	.contactContainer {
		padding: 25px;
		width: 90%;
	}

	.contactTitle {
		font-size: 20px;
	}

	.contactButton {
		font-size: 13px !important;
		padding: 7px !important;
	}

	.contact_images .image_1 {
		top: 0;
		width: 30px;
		height: auto;
	}

	.contact_images .image_2 {
		top: 12%;
		width: 20px;
		height: auto;
	}
}
