.footerContainer {
	padding-bottom: 20px;
	padding-top: 20px;
}

.footer_left_content {
	align-items: flex-start;
}

.footer_right_content {
	align-items: flex-end;
	justify-content: flex-end;
}

.footer_content_section {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: auto;
}

.footerLogo {
	width: 50px;
	height: 50px;
	margin-bottom: 15px;
}

.footerText {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	letter-spacing: -0.5px;
	color: #696969;
	margin: 0 !important;
}

.socialMediaButtonsContainer {
	margin-bottom: 15px;
	min-height: 50px;
}

.socialMediaButton {
	background: transparent !important;
	border-width: 0 !important;
}

.footerListText {
	font-family: Rubik !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 15px !important;
	letter-spacing: -0.5px !important;
	color: #696969 !important;
	margin-right: 7px;
	margin-left: 7px;
	text-decoration: none;
}

.socialMediaButton:focus,
.footerListText:focus {
	outline-width: 0 !important;
	border-width: 0 !important;
	box-shadow: none !important;
}

@media only screen and (max-width: 767px) {
	.footer_left_content {
		align-items: center;
		justify-content: center;
	}

	.footerLogo {
		width: 40px;
		height: 40px;
	}

	.footer_right_content {
		align-items: center;
		justify-content: center;
	}

	.footer_content_section {
		height: auto;
		padding-top: 25px;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.footerLogo {
		width: 40px;
		height: 40px;
	}
	.footer_content_section {
		align-items: center;
		justify-content: center;
	}
}
