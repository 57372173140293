.home_html {
	background: var(--second-black);
}

.notfoundContainer {
	background: var(--main-color);
	height: 100vh;
}

.contentContainer {
	align-items: center;
	justify-content: center;
	display: flex;
	height: calc(100% - 70px);
}

.notfoundImageContainer {
	align-items: center;
	justify-content: flex-end;
	display: flex;
	height: 100%;
}

.notffoundImg {
	width: 70%;
	height: auto;
}

.info {
	align-items: center;
	justify-content: flex-start;
	display: flex;
	height: 100%;
}

@media only screen and (max-width: 767px) {
	.notfoundImageContainer,
	.info {
		align-items: center;
		justify-content: center;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.notfoundImageContainer,
	.info {
		align-items: center;
		justify-content: center;
	}
}
