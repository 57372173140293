.privacyContainer {
	background: var(--main-color);
}

.privacyContent {
	padding-top: 15px;
	padding-bottom: 15px;
	height: calc(100vh - 70px) !important;
	overflow-y: scroll;
	scroll-behavior: smooth;
}

.privacyTitle {
	font-weight: 700;
}

.listGroupItem {
	background-color: transparent !important;
	background: transparent !important;
	border: none !important;
	color: #000 !important;
	font-weight: 700 !important;
}

ul li {
	line-height: 25px;
	margin-bottom: 10px;
}

.underline {
	text-decoration: underline;
}
