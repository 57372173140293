.custom_link_logo_brand {
	font-size: 30px;
	color: var(--main-black);
	text-decoration: none;
	display: flex;
	align-items: center;
}

.custom_link_logo_brand_text {
	letter-spacing: -0.5px;
	font-family: Rubik;
	font-style: normal;
	font-weight: bold;
	line-height: 35px;
	display: inline;
	margin-inline-start: 15px;
}

.custom_link_nav_link {
	font-size: 18px;
	color: var(--main-black);
	text-decoration: none;
	display: inline;
	margin-left: 15px;
	letter-spacing: -0.5px;
}

.navContainer {
	z-index: 5;
}

.loaderContainer {
	width: 100vw;
	height: 100vh;
	background-color: var(--main-color);
	display: flex;
	align-items: center;
	justify-content: center;
}
