.home_html {
	background: var(--second-black);
}

.info_items_main_container {
	margin-top: -100px;
	display: flex;
	justify-content: center;
	z-index: 9;
}

.info_items_container {
	background: var(--third-black);
	border-radius: 5px 5px 0px 5px;
	padding: 40px;
	padding-bottom: 50px;
	padding-top: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	overflow: hidden;
}

.info_text_container {
	margin-top: 10px;
	margin-bottom: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.info_items_title {
	font-family: Rubik;
	font-style: normal;
	font-weight: bold;
	font-size: 45px;
	line-height: 116.1%;
	letter-spacing: -0.5px;
	color: var(--main-white);
	text-align: center;
}

.info_items_subtitle {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 25px;
	line-height: 116.1%;
	text-align: center;
	letter-spacing: -0.5px;
	color: var(--gray-color);
	width: 85%;
	margin-top: 10px;
}

.card_container {
	background: var(--gray-black) !important;
	border: none !important;
}

.card_item_container {
	background: var(--second-black) !important;
	border-radius: 5px !important;
	margin-left: 5px !important;
	padding: 20px !important;
	height: 100% !important;
	margin-right: 10% !important;
	margin-left: 10% !important;
}

.card_image {
	max-width: 50px;
	max-height: 50px;
}

.card_body {
	margin-top: 10px !important;
	padding-left: 0 !important;
	margin-left: 0 !important;
}

.card_title {
	font-family: Rubik !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 20px !important;
	letter-spacing: -0.5px !important;
	color: #ffffff !important;
}

.card_subtitle {
	font-family: Rubik !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 20px !important;
	letter-spacing: -0.5px !important;
	color: #696969 !important;
	margin-top: 15px !important;
}

.first_box {
	position: absolute;
	left: 0;
	top: 5%;
	z-index: 9;
}

.first_box .image_1 {
	position: absolute;
	left: 15px;
	width: 75px;
	height: 75px;
}

.first_box .image_2 {
	position: absolute;
	left: -17px;
	top: 70px;
	width: 40px;
	height: 40px;
}

.first_box .image_3 {
	position: absolute;
	left: 65px;
	top: 100px;
	width: 30px;
	height: 30px;
}

.second_box {
	position: absolute;
	right: 0;
	top: 5%;
	z-index: 9;
}

.second_box .image_1 {
	position: absolute;
	right: 25px;
	width: 20px;
	height: 20px;
}

.second_box .image_2 {
	position: absolute;
	right: 70px;
	top: 30px;
	width: 60px;
	height: 60px;
}

.second_box .image_3 {
	position: absolute;
	right: -45px;
	top: 70px;
	width: 105px;
	height: 105px;
}

.mock_container {
	padding-top: 80px;
	padding-bottom: 80px;
	margin-top: 50px;
	position: relative;
}

.mock_content_col {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 9;
	position: relative;
}

.mock_img {
	transform: scale(0.9);
}

.mock_text {
	padding-top: 150px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.mock_title {
	font-family: Rubik;
	font-style: normal;
	line-height: 52px;
	letter-spacing: -0.5px;
	text-align: left;
	font-weight: bold;
	font-size: 45px;
	color: #ffffff;
}

.mock_subtitle {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 25px;
	line-height: 116.1%;
	letter-spacing: -0.5px;
	color: #696969;
	margin-top: 30px;
}

.mock_images_container {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 0;
}

.mock_images_container .first_mock {
	position: absolute;
	right: 0;
	bottom: 20px;
}

.mock_one .big_black_box {
	position: absolute;
	left: 7%;
	top: 70px;
}

.mock_one .small_black_box {
	position: absolute;
	left: 5%;
	bottom: 25%;
	width: 60px;
	height: 50px;
	filter: brightness(2.5);
}

.mock_one .small_green_box {
	position: absolute;
	left: 43%;
	top: 45%;
	width: 60px;
	height: 50px;
	filter: brightness(1.5);
	transform: rotate(-20deg);
}

.mock_one .small_green_circle {
	position: absolute;
	left: 41%;
	top: 55%;
	width: 20px;
	height: 20px;
	filter: brightness(1.5);
}

.mock_two .big_green_circle {
	position: absolute;
	left: 0;
	bottom: -50px;
}

.mock_two .big_black_box {
	position: absolute;
	right: 7%;
	top: 70px;
}

.mock_two .small_black_box {
	position: absolute;
	right: 5%;
	bottom: 25%;
	width: 60px;
	height: 50px;
	filter: brightness(2.5);
}

.mock_two .small_green_box {
	position: absolute;
	right: 43%;
	top: 45%;
	width: 60px;
	height: 50px;
	filter: brightness(1.5);
	transform: rotate(-20deg);
}

.mock_two .small_green_circle {
	position: absolute;
	right: 41%;
	top: 55%;
	width: 20px;
	height: 20px;
	filter: brightness(1.5);
}

.mock_three .big_black_box {
	position: absolute;
	left: 7%;
	top: 70px;
}

.mock_three .small_black_box {
	position: absolute;
	left: 5%;
	bottom: 25%;
	width: 60px;
	height: 50px;
	filter: brightness(2.5);
}

.mock_three .small_green_box {
	position: absolute;
	left: 43%;
	top: 45%;
	width: 60px;
	height: 50px;
	filter: brightness(1.5);
	transform: rotate(-20deg);
}

.mock_three .small_green_circle {
	position: absolute;
	left: 41%;
	top: 55%;
	width: 20px;
	height: 20px;
	filter: brightness(1.5);
}

@media only screen and (max-width: 767px) {
	.info_items_container {
		padding: 20px;
		padding-bottom: 30px;
		padding-top: 50px;
	}

	.info_items_title {
		font-size: 20px;
	}

	.info_items_subtitle {
		font-size: 15px;
		margin-top: 0px;
	}

	.card_item_container {
		padding: 15px !important;
	}

	.card_image {
		max-width: 28px !important;
		max-height: auto !important;
	}

	.card_body {
		margin-top: 5px !important;
	}

	.card_title {
		font-size: 17px !important;
	}

	.card_subtitle {
		font-size: 14px !important;
		margin-top: 10px !important;
	}

	.first_box {
		position: absolute;
		left: 0;
		top: 2%;
	}

	.first_box .image_1 {
		position: absolute;
		left: 15px;
		width: 30px;
		height: 30px;
	}

	.first_box .image_2 {
		position: absolute;
		left: -7px;
		top: 50px;
		width: 17px;
		height: 17px;
	}

	.first_box .image_3 {
		position: absolute;
		left: 25px;
		top: 100px;
		width: 13px;
		height: 13px;
	}

	.second_box {
		top: 2%;
	}

	.second_box .image_1 {
		right: 15px;
		width: 10px;
		height: 10px;
	}

	.second_box .image_2 {
		right: 40px;
		top: 20px;
		width: 22px;
		height: 22px;
	}

	.second_box .image_3 {
		right: -15px;
		top: 70px;
		width: 45px;
		height: 45px;
	}

	.mock_container {
		padding-top: 20px;
		padding-bottom: 20px;
		margin-top: 0px;
	}

	.mock_content_col {
		align-items: center;
		justify-content: flex-start;
	}

	.mock_img {
		transform: scale(0.5);
	}

	.mock_text {
		padding-top: 0px;
		align-items: center;
	}

	.mock_title {
		font-size: 18px;
		text-align: center;
		line-height: 20px;
	}

	.mock_subtitle {
		font-size: 14px;
		margin-top: 0px;
		text-align: center;
	}

	.mock_images_container .first_mock {
		width: 80px;
		height: auto;
		bottom: 110px;
	}

	.mock_one .big_black_box {
		left: 10%;
		top: 150px;
		width: 250px;
		height: auto;
	}

	.mock_one .small_black_box {
		left: 5%;
		bottom: 25%;
		width: 40px;
		height: auto;
	}

	.mock_one .small_green_box {
		left: 80%;
		top: 45%;
		width: 30px;
		height: auto;
	}

	.mock_one .small_green_circle {
		left: 77%;
		top: 50%;
		width: 10px;
		height: 10px;
	}

	.mock_two .big_green_circle {
		width: 80px;
		height: auto;
	}

	.mock_two .big_black_box {
		left: 10%;
		top: 220px;
		width: 250px;
		height: auto;
	}

	.mock_two .small_black_box {
		right: 5%;
		bottom: 25%;
		width: 35px;
		height: auto;
	}

	.mock_two .small_green_box {
		right: 82%;
		top: 45%;
		width: 30px;
		height: auto;
	}

	.mock_two .small_green_circle {
		right: 88%;
		top: 55%;
		width: 10px;
		height: auto;
	}

	.mock_three .big_black_box {
		top: 170px;
		width: 250px;
		height: auto;
	}

	.mock_three .small_black_box {
		width: 40px;
		height: auto;
	}

	.mock_three .small_green_box {
		left: 83%;
		top: 42%;
		width: 40px;
		height: auto;
	}

	.mock_three .small_green_circle {
		left: 83%;
		top: 50%;
		width: 10px;
		height: auto;
	}
}

@media (min-width: 768px) and (max-width: 1200px) {
	.info_items_container {
		padding: 30px;
		padding-bottom: 40px;
		padding-top: 50px;
	}

	.info_items_title {
		font-size: 25px;
	}

	.info_items_subtitle {
		font-size: 18px;
		margin-top: 0px;
	}

	.card_item_container {
		padding: 20px !important;
	}

	.card_image {
		max-width: 30px !important;
		max-height: auto !important;
	}

	.card_body {
		margin-top: 5px !important;
	}

	.card_title {
		font-size: 17px !important;
	}

	.card_subtitle {
		font-size: 14px !important;
		margin-top: 10px !important;
	}

	.first_box {
		position: absolute;
		left: 0;
		top: 2%;
	}

	.first_box .image_1 {
		position: absolute;
		left: 15px;
		width: 35px;
		height: 35px;
	}

	.first_box .image_2 {
		position: absolute;
		left: -7px;
		top: 50px;
		width: 20px;
		height: 20px;
	}

	.first_box .image_3 {
		position: absolute;
		left: 25px;
		top: 100px;
		width: 15px;
		height: 15px;
	}

	.second_box {
		top: 2%;
	}

	.second_box .image_1 {
		right: 15px;
		width: 10px;
		height: 10px;
	}

	.second_box .image_2 {
		right: 40px;
		top: 20px;
		width: 25px;
		height: 25px;
	}

	.second_box .image_3 {
		right: -25px;
		top: 70px;
		width: 65px;
		height: 65px;
	}

	.mock_container {
		padding-top: 20px;
		padding-bottom: 20px;
		margin-top: 0px;
	}

	.mock_content_col {
		align-items: center;
		justify-content: flex-start;
	}

	.mock_img {
		transform: scale(0.6);
	}

	.mock_text {
		padding-top: 250px;
		align-items: center;
	}

	.mock_title {
		font-size: 24px;
		text-align: center;
		line-height: 25px;
	}

	.mock_subtitle {
		font-size: 18px;
		margin-top: 10px;
		text-align: center;
	}

	.mock_images_container .first_mock {
		width: 120px;
		height: auto;
		bottom: 10px;
	}

	.mock_one .big_black_box {
		left: 12%;
		top: 130px;
		width: 300px;
		height: auto;
	}

	.mock_one .small_black_box {
		left: 5%;
		bottom: 25%;
		width: 40px;
		height: auto;
	}

	.mock_one .small_green_box {
		left: 40%;
		top: 45%;
		width: 50px;
		height: auto;
	}

	.mock_one .small_green_circle {
		left: 40%;
		top: 55%;
		width: 15px;
		height: 15px;
	}

	.mock_two .big_green_circle {
		width: 120px;
		height: auto;
	}

	.mock_two .big_black_box {
		right: 12%;
		top: 130px;
		width: 300px;
		height: auto;
	}

	.mock_two .small_black_box {
		right: 5%;
		bottom: 25%;
		width: 35px;
		height: auto;
	}

	.mock_two .small_green_box {
		right: 38%;
		top: 45%;
		width: 40px;
		height: auto;
	}

	.mock_two .small_green_circle {
		right: 43%;
		top: 53%;
		width: 15px;
		height: auto;
	}

	.mock_three .big_black_box {
		top: 130px;
		width: 300px;
		height: auto;
	}

	.mock_three .small_black_box {
		width: 40px;
		height: auto;
	}

	.mock_three .small_green_box {
		left: 43%;
		top: 42%;
		width: 40px;
		height: auto;
	}

	.mock_three .small_green_circle {
		left: 42%;
		top: 50%;
		width: 15px;
		height: auto;
	}
}
